<template>
  <a-modal v-model="visible" title="详情" :destroy-on-close="true" :footer="null">
    <slot></slot>
  </a-modal>
</template>

<script>
export default {
  name: 'InfoModal',
  data () {
    return {
      visible: false
    }
  },
  methods: {
    show () {
      this.visible = true
    }
  }
}
</script>

<style>

</style>
