<template>
  <div>
    <a-table
      class="custom-table scroll-hidden-table"
      :columns="columns"
      :data-source="dataSource"
      row-key="id"
      :components="components"
      :pagination="{pageSize: 5, size: 'small', total:dataSource.length,  showTotal: total => `共 ${total} 条`}"
      :loading="loading"
      :scroll="{x: scrollX}"
    >
      <template slot="name" slot-scope="text, record">
        <content-tooltip v-if="text" :title="text">
          <a slot="tooltipBody" @click="$refs.alertDrawer.show(record.id)">
            {{ text }}
          </a>
        </content-tooltip>
        <span v-else>-</span>
      </template>
      <template slot="severity" slot-scope="text">
        <alert-severity-tag :severity="text"></alert-severity-tag>
      </template>
      <template slot="duration" slot-scope="text, record">
        {{
          duration(
            record.triggered_at,
            record.recovered_at
          )
        }}
      </template>
      <template slot="dailyCount" slot-scope="text">
        {{ text }} 次
      </template>
    </a-table>
    <alert-drawer ref="alertDrawer"></alert-drawer>
  </div>
</template>

<script>
import moment from 'moment'
import { humanAlertDuration } from '@/utils'
import AlertDrawer from '@/components/drawer/AlertDrawer/index.vue'
import AlertSeverityTag from '@/components/tag/AlertSeverityTag'
import ContentTooltip from '@/components/ContentTooltip'

export default {
  name: 'OverviewAlerts',
  props: {
    dataSource: {
      type: Array
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AlertSeverityTag,
    ContentTooltip,
    AlertDrawer
  },
  data () {
    return {
      columns: [
        {
          dataIndex: 'severity',
          title: '严重性',
          width: 90,
          scopedSlots: {
            customRender: 'severity'
          }
        },
        {
          dataIndex: 'name',
          title: '描述',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'triggered_at',
          title: '触发时间',
          width: 200,
          scopedSlots: {
            customRender: 'triggeredAt'
          }
        },
        {
          dataIndex: 'duration',
          title: '持续时间',
          width: 150,
          ellipsis: true,
          scopedSlots: {
            customRender: 'duration'
          },
          sorter: (a, b) => {
            const r1 = a.recovered_at ? moment(a.recovered_at) : moment()
            const t1 = moment(a.triggered_at)
            const r2 = b.recovered_at ? moment(b.recovered_at) : moment()
            const t2 = moment(b.triggered_at)
            return r1.diff(t1) - r2.diff(t2)
          }
        },
        {
          dataIndex: 'daily_count',
          title: '今日累计',
          width: 80,
          scopedSlots: {
            customRender: 'dailyCount'
          }
        }
      ],
      components: {
        header: {}
      },
      sorterTime: moment()
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => {
        sum += column.width
      })
      return sum
    }
  },
  methods: {
    duration (triggeredAt, recoveredAt) {
      return humanAlertDuration(triggeredAt, recoveredAt)
    }
  }
}
</script>
