<template>
  <a-descriptions
    :column="1"
    class="description-overflow-hidden description-item-12px"
  >
    <a-descriptions-item label="名称">
      <content-tooltip
        v-if="detail.name"
        :title="detail.name"
        :body="detail.name"
      ></content-tooltip>
      <span v-else>-</span>
    </a-descriptions-item>
    <a-descriptions-item label="中间件类型">
      <content-tooltip
        v-if="detail.middleware_type"
        :title="$t(`middleware_type.${detail.middleware_type}`)"
        :body="$t(`middleware_type.${detail.middleware_type}`)"
      ></content-tooltip>
      <span v-else>-</span>
    </a-descriptions-item>
    <a-descriptions-item label="所属操作系统">
      <content-tooltip
        v-if="detail.os"
        :title="detail.os.name"
        :body="detail.os.name"
      ></content-tooltip>
      <span v-else>-</span>
    </a-descriptions-item>
    <a-descriptions-item label="版本">
      <content-tooltip
        v-if="detail.version"
        :title="detail.version"
        :body="detail.version"
      ></content-tooltip>
      <span v-else>-</span>
    </a-descriptions-item>
    <a-descriptions-item label="创建时间">
      <content-tooltip
        v-if="detail.created_at"
        :title="detail.created_at"
        :body="detail.created_at"
      ></content-tooltip>
      <span v-else>-</span>
    </a-descriptions-item>
    <a-descriptions-item label="更新时间" v-if="showMore">
      <content-tooltip
        v-if="detail.updated_at"
        :title="detail.updated_at"
        :body="detail.updated_at"
      ></content-tooltip>
      <span v-else>-</span>
    </a-descriptions-item>
  </a-descriptions>
</template>

<script>
import ContentTooltip from '@/components/ContentTooltip'

export default {
  name: 'HardwareDescription',
  props: {
    detail: {
      type: Object
    },
    showMore: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ContentTooltip
  }
}
</script>
