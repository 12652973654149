<template>
  <a-icon :component="component"></a-icon>
</template>

<script>
export default {
  name: 'PointerIcon',
  data () {
    return {
      component: {
        template: `
          <svg viewBox="0 0 1028 1024" width="1em" height="1em">
              <path d="M512 736.775526a179.006754 179.006754 0 0 1 122.05006 48.413191L509.152165 0 389.94994 784.37505a180.227255 180.227255 0 0 1 122.05006-47.599524z" fill="currentColor" p-id="2496"></path>
            <path d="M511.593166 917.002781m-106.997218 0a106.997219 106.997219 0 1 0 213.994437 0 106.997219 106.997219 0 1 0-213.994437 0Z" fill="currentColor" p-id="2497"></path>
          </svg>            
        `
      }
    }
  }
}
</script>
