<template>
  <a-drawer
    class="detail-drawer"
    :closable="false"
    :body-style="{ padding: '8px 16px', overflow: 'hidden' }"
    :destroy-on-close="true"
    :visible="visible"
    :width="1080"
    @close="closeDrawer"
  >
    <a-tabs v-model="tabKey" v-if="detail.id">
      <a-tab-pane key="overview" tab="总览">
        <a-row
          :gutter="16"
          style="flex-flow: nowrap; height: calc(100vh - 80px); overflow: auto"
        >
          <!-- 健康仪表盘 -->
          <a-col :span="8" style="margin-bottom: 16px">
            <div class="module-container" style="height: 208px">
              <div
                style="
                  height: 60px;
                  margin-bottom: 8px;
                  display: grid;
                  grid-template-rows: 1fr 1fr;
                  justify-items: center;
                  grid-template-columns: 2fr 3fr;
                  line-height: 30px;
                "
              >
                <div style="color: rgba(0, 0, 0, 0.5); font-size: 12px;">
                  资源状态
                </div>
                <source-status-tag :status="detail.status"></source-status-tag>
                <div style="color: rgba(0, 0, 0, 0.5); font-size: 12px;">
                  监控状态
                </div>
                <div>
                  <monitor-status-tag
                    v-for="item in monitorStatuses"
                    :key="item.name"
                    :status="item.value"
                    :name="item.name"
                  ></monitor-status-tag>
                </div>
              </div>
              <dashboard-chart
                v-if="showScoreChart"
                chart-id="overview-health-chart"
                :height="110"
                :chart-data="dashboardData"
              ></dashboard-chart>
            </div>
          </a-col>
          <!-- 硬件信息 -->
          <a-col :span="8" style="margin-bottom: 16px">
            <div class="module-container" style="height: 208px">
              <div class="module-header-fs14" style="margin-bottom: 14px;">软件信息</div>
              <div
                style="
                  position: absolute;
                  right: 10px;
                  top: 12px;
                  font-size: 11px;
                "
              >
                <a @click="$refs.hardwareModal.show()">更多</a>
              </div>

              <hardware-description :detail="detail"></hardware-description>
              <info-modal ref="hardwareModal" class="description-in-modal">
                <template>
                  <hardware-description :detail="detail" :show-more="true"></hardware-description>
                </template>
              </info-modal>
            </div>
          </a-col>
          <!-- 监控信息 -->
          <a-col :span="8" style="margin-bottom: 16px">
            <div class="module-container" style="height: 208px">
              <div class="module-header-fs14" style="margin-bottom: 14px;">监控信息</div>
              <a-descriptions
                :column="1"
                class="description-overflow-hidden description-item-12px"
              >
                <a-descriptions-item label="ID">
                  <content-tooltip
                    v-if="detail.id"
                    :title="detail.id.replaceAll('-', '')"
                    :body="detail.id.replaceAll('-', '')"
                  ></content-tooltip>
                  <span v-else>-</span>
                </a-descriptions-item>
                <a-descriptions-item label="所属群组">
                  <a-tag v-for="group in detail.groups" :key="group.id">
                    {{ group.name }}
                  </a-tag>
                </a-descriptions-item>
                <a-descriptions-item label="IP 地址">
                  <content-tooltip
                    v-if="detail.os"
                    :title="detail.os.monitor_address"
                    :body="detail.os.monitor_address"
                  ></content-tooltip>
                  <span v-else>-</span>
                </a-descriptions-item>
                <a-descriptions-item label="监控端口">
                  <content-tooltip
                    v-if="detail.monitor_port"
                    :title="detail.monitor_port"
                    :body="detail.monitor_port"
                  ></content-tooltip>
                  <span v-else>-</span>
                </a-descriptions-item>
              </a-descriptions>
            </div>
          </a-col>
          <!-- 告警情况 -->
          <a-col :span="24" style="margin-bottom: 16px">
            <div class="module-container" style="height: 320px">
              <div class="module-header-fs14" style="margin-bottom: 14px;">告警情况</div>
              <overview-alerts :dataSource="alertsData" :loading="loading"></overview-alerts>
            </div>
          </a-col>
          <!-- 风险情况 -->
          <!-- <a-col :span="24" style="margin-bottom: 16px">
            <div class="module-container" style="height: 340px">
              <div class="module-header-fs14" style="margin-bottom: 14px;">风险情况</div>
              <display-table :columns="RiskColumns"></display-table>
            </div>
          </a-col> -->
          <a-col :span="24">
            <div class="module-container">
              <software-page :detail="detail"></software-page>
            </div>
          </a-col>
        </a-row>
      </a-tab-pane>

      <a-tab-pane key="monitor" tab="指标详情">
        <div
          style="
            padding: 0 16px;
            flex-flow: nowrap;
            height: calc(100vh - 80px);
            overflow: auto;
          "
        >
          <monitor-item-table
            :get-func="getItemListFunc"
            :history-func="historyFunc"
            :source-id="detail.id"
          ></monitor-item-table>
        </div>
      </a-tab-pane>

      <a-tab-pane key="rule" tab="告警规则">
        <div
          style="
            padding: 0 16px;
            flex-flow: nowrap;
            height: calc(100vh - 80px);
            overflow: auto;
          "
        >
          <monitor-trigger-table
            :get-func="getTriggerFunc"
            :source-id="detail.id"
            :update-func="updateFunc"
          ></monitor-trigger-table>
        </div>
      </a-tab-pane>
      <a-tab-pane key="alert" tab="历史告警">
        <div style="padding: 0 16px; flex-flow: nowrap; height: calc(100vh - 80px); overflow: auto">
          <alert-table source-type="middleware" :source-id="detail.id"></alert-table>
        </div>
      </a-tab-pane>
    </a-tabs>
  </a-drawer>
</template>

<script>
import store from '@/store'
import {
  getMiddleware,
  getMiddlewareAlertList,
  getMiddlewareMonitorStatusList,
  getMiddlewareMonitorItemList,
  getMiddlewareMonitorHistory,
  getMiddlewareMonitorTriggerList,
  updateMiddlewareMonitorTrigger,
  getMiddlewareHealthScore
} from '@/api/middleware'
import MonitorStatusTag from '@/components/tag/MonitorStatusTag'
import ContentTooltip from '@/components/ContentTooltip'
import DashboardChart from '@/components/chart/DashboardChart'
import HardwareDescription from './modules/HardwareDescription.vue'
import InfoModal from '@/components/source-detail-page/InfoModal'
// import DisplayTable from '@/components/source-detail-page/DisplayTable'
import MonitorItemTable from '@/components/table/MonitorItemTable'
import MonitorTriggerTable from '@/components/table/MonitorTriggerTable'
import OverviewAlerts from '@/components/source-detail-page/OverviewAlerts'
import SoftwarePage from './SoftwarePage/index.vue'
import SourceStatusTag from '@/components/tag/SourceStatusTag'
import AlertTable from '@/components/table/AlertTable.vue'

export default {
  name: 'MiddlewareDrawer',
  components: {
    AlertTable,
    MonitorStatusTag,
    DashboardChart,
    HardwareDescription,
    InfoModal,
    ContentTooltip,
    // DisplayTable,
    MonitorItemTable,
    MonitorTriggerTable,
    SoftwarePage,
    OverviewAlerts,
    SourceStatusTag
  },
  data () {
    return {
      visible: false,
      tabKey: 'overview',
      monitorStatuses: [],
      getItemListFunc: getMiddlewareMonitorItemList,
      historyFunc: getMiddlewareMonitorHistory,
      getTriggerFunc: getMiddlewareMonitorTriggerList,
      updateFunc: updateMiddlewareMonitorTrigger,
      detail: {
        id: '',
        name: '',
        version: '',
        monitor_port: '',
        middleware_type: 'unknown',
        os: {
          id: '',
          name: '',
          monitor_address: ''
        },
        created_at: '',
        updated_at: '',
        groups: [],
        status: 'unknown'
      },
      loading: false,
      alertsData: [],
      RiskColumns: [
        {
          dataIndex: 'type',
          align: 'center',
          title: '风险分类',
          width: 80,
          scopedSlots: {
            customRender: 'type'
          }
        },
        {
          dataIndex: 'monitor',
          align: 'center',
          title: '监控项',
          width: 80,
          scopedSlots: {
            customRender: 'monitor'
          }
        },
        {
          dataIndex: 'current_monitor',
          align: 'center',
          title: '当前指标',
          width: 80,
          scopedSlots: {
            customRender: 'currentMonitor'
          }
        },
        {
          dataIndex: 'description',
          align: 'description',
          title: '描述',
          width: 80,
          scopedSlots: {
            customRender: 'description'
          }
        }
      ],
      showScoreChart: false,
      dashboardData: []
    }
  },
  methods: {
    show (id) {
      getMiddleware(id)
        .then(res => {
          this.detail = res.data
        })
        .finally(() => {
          this.visible = true
        })
      getMiddlewareHealthScore(id).then(res => {
        this.dashboardData = [{ value: res.data }]
      }).finally(() => {
        this.showScoreChart = true
      })
      getMiddlewareMonitorStatusList(id).then(res => {
        this.monitorStatuses = res.data.data
      })
      const params = {
        recovered: false,
        source_id: id
      }
      this.loading = true
      getMiddlewareAlertList(params).then(res => {
        this.alertsData = res.data.data
      }).finally(() => {
        this.loading = false
      })
    },
    closeDrawer () {
      this.visible = false
      store.state.cancelRequest.promiseArr.forEach((e, index) => {
        e.cancel('取消请求')
      })
      store.commit('cancelRequest/CLEAR_PROMISE_ARR')
      this.detail = {
        id: '',
        name: '',
        version: '',
        monitor_port: '',
        middleware_type: 'unknown',
        os: {
          id: '',
          name: '',
          monitor_address: ''
        },
        created_at: '',
        updated_at: '',
        groups: [],
        status: 'unknown'
      }
      this.tabKey = 'overview'
      this.alertsData = []
      this.monitorStatuses = []
      this.showScoreChart = false
    }
  }
}
</script>
