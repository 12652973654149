<template>
  <div>
    <div :id="chartId" style="position: relative;">
    </div>
    <pointer-icon :style="pointerStyle" id="pointer"></pointer-icon>
  </div>
</template>

<script>
import { Chart } from '@antv/g2'
import PointerIcon from '@/components/icon/PointerIcon'

const chart = {}

const views = []

const step = 180 / 100
const ROTATE = 270

export default {
  name: 'DashboardChart',
  props: {
    chartData: {
      type: Array,
      default: () => {
        return [{ value: 0 }]
      }
    },
    chartId: {
      type: String,
      required: true
    },
    height: {
      type: Number,
      default: 200
    },
    containerHeight: {
      type: Number,
      default: 280
    }
  },
  components: {
    PointerIcon
  },
  computed: {
    pointerStyle () {
      if (this.chartData.length) {
        const rotate = ROTATE + this.chartData[0].value * step
        return {
          transformOrigin: 'bottom',
          transform: `rotateZ(${rotate}deg)`,
          transition: 'all .3s',
          fontSize: '30px'
        }
      } else {
        return {
          transformOrigin: 'bottom',
          transform: 'rotateZ(270deg)',
          transition: 'all .3s',
          fontSize: '30px'
        }
      }
    }
  },
  mounted () {
    if (chart[this.chartId]) chart[this.chartId].destroy()
    chart[this.chartId] = new Chart({
      container: this.chartId,
      autoFit: true,
      height: this.height,
      appendPadding: [0, 0, 4, 0]
    })

    chart[this.chartId].scale('value', {
      min: 0,
      max: 100,
      tickInterval: 10
    })

    chart[this.chartId].axis(false)

    chart[this.chartId].data = this.chartData

    // view0
    views[0] = chart[this.chartId].createView()
    views[0].coordinate('polar', {
      startAngle: (-8 / 8) * Math.PI,
      endAngle: (0 / 8) * Math.PI,
      radius: 0.74
    })
    views[0].axis('value', {
      line: null,
      label: {
        offset: -18,
        style: {
          fontSize: 11,
          textAlign: 'center',
          textBaseline: 'middle',
          fill: '#686E7E'
        },
        formatter: (v) => {
          if (v % 20 === 0) return v
        }
      },
      tickLine: {
        length: 0
      },
      grid: null
    })
    views[0]
      .point()
      .position('value*1')
      .color('#d8d8d8')
      .animate({
        appear: {
          animation: 'fade-in'
        }
      })

    views[0].annotation().arc({
      top: false,
      start: [0, 1],
      end: [100, 1],
      offsetY: 2,
      style: {
        // 底灰色
        stroke: 'l(0) 0:#ff504c 1:#77ce79',
        lineWidth: 14,
        lineDash: [1, 3]
      }
    })

    views[0].annotation().html({
      html: document.getElementById('pointer'),
      alignX: 'middle',
      alignY: 'top',
      offsetY: -24,
      offsetX: -16
    })

    // view1
    views[1] = chart[this.chartId].createView()
    views[1].coordinate('polar', {
      startAngle: (-8 / 8) * Math.PI,
      endAngle: (0 / 8) * Math.PI,
      radius: 0.9
    })
    views[1]
      .line()
      .position('value*1')
      .color('#d8d8d8')
      .animate({
        appear: {
          animation: 'fade-in'
        }
      })
    views[1].annotation().arc({
      top: false,
      start: [0, 1],
      end: [100, 1],
      style: {
        // 底灰色
        stroke: '#F2F6F7',
        lineWidth: 6,
        lineDash: null
      }
    })
    views[1].annotation().arc({
      start: [0, 1],
      end: [this.chartData[0].value, 1],
      style: {
        stroke: '#1890FF',
        lineWidth: 8,
        lineDash: null,
        lineCap: 'round'
      }
    })
    views[1].annotation().arc({
      start: [0, 1],
      end: [0.1, 1],
      style: {
        stroke: '#fff',
        lineWidth: 6,
        lineDash: null,
        lineCap: 'round'
      }
    })

    if (this.chartData[0].value > 1) {
      views[1].annotation().arc({
        start: [this.chartData[0].value - 0.1, 1],
        end: [this.chartData[0].value, 1],
        style: {
          stroke: '#fff',
          lineWidth: 6,
          lineDash: null,
          lineCap: 'round'
        }
      })
    }

    chart[this.chartId]
      .point()
      .position('value*1')
      .shape('pointers')
      .color('#d8d8d8')
      .size(10)
      .animate({
        appear: {
          animation: 'fade-in'
        }
      })

    chart[this.chartId].render()
    this.$forceUpdate()
  }
}
</script>

<style lang="less">
#pointer {
  position: absolute;
  color: #096dd9;
}
</style>
