<template>
  <a-card
    :body-style="{ padding: 4 }"
    :bordered="false"
    :head-style="{ borderBottom: 0 }"
    size="small"
    :title="item.title"
  >
    <a-space slot="extra" v-if="modalBtnVisible">
      <a-icon type="fullscreen" @click="$refs.chartModal.show(item)"></a-icon>
    </a-space>

    <line-chart
      :datetime-range="datetimeRange"
      :history-func="historyFunc"
      :item="item"
      :source-id="sourceId"
      :height="280"
    ></line-chart>

    <multi-line-chart-modal
      ref="chartModal"
      :default-value="datetimeRange"
      :history-func="historyFunc"
      :source-id="sourceId"
    ></multi-line-chart-modal>
  </a-card>
</template>

<script>
import moment from 'moment'
import MultiLineChartModal from '@/components/modal/MultiLineChartModal'
import LineChart from './modules/LineChart'

export default {
  name: 'MonitorChartCard',
  components: {
    LineChart,
    MultiLineChartModal
  },
  props: {
    datetimeRange: {
      type: Array,
      default: () => {
        return [moment().subtract(1, 'hour'), moment()]
      }
    },
    historyFunc: {
      type: Function,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    sourceId: {
      type: String
    },
    modalBtnVisible: {
      type: Boolean,
      default: true
    }
  }
}
</script>
