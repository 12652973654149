<template>
  <a-tooltip :placement="placement" :overlayClassName="overlayClassName">
    <template slot="title">
      <slot name="tooltipTitle">{{ title }}</slot>
    </template>
    <slot name="tooltipBody">
      <span>{{ body }}</span>
    </slot>
  </a-tooltip>
</template>

<script>
export default {
  name: 'ContentTooltip',
  props: {
    title: {
      type: [String, Number]
    },
    body: {
      type: [String, Number]
    },
    placement: {
      type: String,
      default: 'topLeft'
    },
    overlayClassName: {
      type: String,
      default: ''
    }
  }
}
</script>
