<template>
  <a-spin :spinning="spinning">
    <div :id="chartId" :style="{ height: `${height}px` }"></div>
  </a-spin>
</template>

<script>
import moment from 'moment'
import DataSet from '@antv/data-set'
import { Chart } from '@antv/g2'
import { humanizeValue } from '@/utils'

const chart = {}

export default {
  name: 'LineChart',
  props: {
    datetimeRange: {
      type: Array,
      default: () => {
        return [moment().subtract(1, 'hour'), moment()]
      }
    },
    height: {
      type: Number,
      default: 240
    },
    historyFunc: {
      type: Function,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    sourceId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      spinning: false
    }
  },
  computed: {
    chartId () {
      return `${this.item.key}.chart`
    }
  },
  mounted () {
    chart[this.chartId] = new Chart({
      container: this.chartId,
      autoFit: true,
      appendPadding: [8, 8, 0, 0]
    })
    chart[this.chartId].scale({
      timestamp: {
        type: 'time',
        mask: 'MM-DD HH:mm'
      },
      value: {
        min: 0,
        nice: true,
        alias: '值',
        formatter: value => humanizeValue(value)
      }
    })
    chart[this.chartId].tooltip({
      showCrosshairs: true
    })
    chart[this.chartId].axis('timestamp', {
      tickLine: null,
      line: null,
      grid: null,
      label: {
        style: {
          fill: '#808080'
        }
      }
    })

    chart[this.chartId].axis('value', {
      grid: {
        line: {
          style: {
            stroke: '#cccccc',
            strokeOpacity: 0.8,
            lineWidth: 1,
            lineDash: [4]
          }
        }
      },
      label: {
        style: {
          fill: '#808080'
        }
      }
    })
    chart[this.chartId].option('slider', {
      trendCfg: {
        isArea: false
      },
      formatter: (value) => {
        return moment(value).format('MM-DD HH:mm')
      }
    })
    this.render()
  },
  methods: {
    render (isUpdate = false) {
      if (isUpdate) {
        chart[this.chartId].clear()
        chart[this.chartId].annotation().clear(true)
      }
      this.spinning = true
      this.historyFunc(this.sourceId, {
        key: this.item.key,
        datetime_from: this.datetimeRange[0].format('YYYY-MM-DD HH:mm'),
        datetime_to: this.datetimeRange[1].format('YYYY-MM-DD HH:mm')
      })
        .then(res => {
          const data = res.data.data
          const ds = new DataSet({
            state: {
              start: this.datetimeRange[0].valueOf(),
              end: this.datetimeRange[1].valueOf()
            }
          })
          const dv = ds.createView('origin').source(data)
          dv.transform({
            type: 'filter',
            callback: function callback (obj) {
              return obj.timestamp >= ds.state.start && obj.timestamp <= ds.state.end
            }
          })

          chart[this.chartId].data(dv.rows)
          chart[this.chartId]
            .line()
            .position('timestamp*value')
            .color('#096DD9')
          chart[this.chartId]
            .area()
            .position('timestamp*value')
            .color('l(90) 0:#096DD9 1:#DAE9F9')
          if (data.length !== 0) {
            let max = data[0]
            let min = data[0]
            for (const datum of data) {
              if (datum.value > max.value) max = datum
              if (datum.value < min.value) min = datum
            }
            chart[this.chartId].annotation().dataMarker({
              point: {
                style: {
                  stroke: '#ff9671'
                }
              },
              direction: 'downward',
              top: true,
              position: [max.timestamp, max.value],
              text: {
                content: `峰值: ${humanizeValue(max.value)}`
              }
            })
            if (max.timestamp !== min.timestamp) {
              chart[this.chartId].annotation().dataMarker({
                point: {
                  style: {
                    stroke: '#ff9671'
                  }
                },
                top: true,
                position: [min.timestamp, min.value],
                text: {
                  content: `谷值: ${humanizeValue(min.value)}`
                }
              })
            }
          }
        })
        .finally(() => {
          chart[this.chartId].render(isUpdate)
          this.spinning = false
        })
    }
  },
  watch: {
    datetimeRange () {
      this.render(true)
    }
  }
}
</script>
