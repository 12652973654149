<template>
  <div class="software-page">
    <div style="margin-bottom: 16px">
      <datetime-range
        :allow-clear="false"
        :default-value="datetimeRange"
        @ok="
          v => {
            datetimeRange = v
          }
        "
      ></datetime-range>
    </div>
    <a-row :gutter="16">
      <a-col
        v-for="item in monitorItems"
        :key="item.key"
        :span="12"
        style="margin-bottom: 16px;"
      >
        <monitor-chart-card
          :datetime-range="datetimeRange"
          :history-func="historyFunc"
          :item="item"
          :source-id="detail.id"
        ></monitor-chart-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import moment from 'moment'
import { getMiddlewareMonitorHistory } from '@/api/middleware'
import DatetimeRange from '@/components/DatetimeRange'
import MonitorChartCard from '@/components/card/MonitorChartCard'

export default {
  name: 'SoftwarePage',
  components: {
    DatetimeRange,
    MonitorChartCard
  },
  props: {
    detail: {
      type: Object
    }
  },
  computed: {
    monitorItems () {
      switch (this.detail.middleware_type) {
        case 'nginx':
          return [
            {
              key: 'nginx.requests.total.rate',
              title: '请求数'
            },
            {
              key: 'nginx.connections.active',
              title: '活跃连接数'
            },
            {
              key: 'nginx.connections.accepted.rate',
              title: '接受连接数'
            },
            {
              key: 'nginx.connections.dropped.rate',
              title: '丢弃连接数'
            },
            {
              key: 'nginx.connections.handled.rate',
              title: '已处理连接数'
            }
          ]
        case 'iis':
          return [
            {
              key: 'perf_counter_en["\\Web Service(_Total)\\Get Requests/Sec", 60]',
              title: '每秒 GET 请求数'
            },
            {
              key: 'perf_counter_en["\\Web Service(_Total)\\Post Requests/Sec", 60]',
              title: '每秒 POST 请求数'
            },
            {
              key: 'perf_counter_en["\\Web Service(_Total)\\Put Requests/Sec", 60]',
              title: '每秒 PUT 请求数'
            },
            {
              key: 'perf_counter_en["\\Web Service(_Total)\\Delete Requests/Sec", 60]',
              title: '每秒 DELETE 请求数'
            },
            {
              key: 'perf_counter_en["\\Web Service(_Total)\\Bytes Received/sec", 60]',
              title: '每秒接收的数据量 (B)'
            },
            {
              key: 'perf_counter_en["\\Web Service(_Total)\\Bytes Sent/sec", 60]',
              title: '每秒发送的数据量 (B)'
            }
          ]
        case 'php_fpm':
          return [
            {
              key: 'php-fpm.slow_requests',
              title: '慢请求数'
            },
            {
              key: 'php-fpm.conn_accepted.rate',
              title: '每秒接受的连接数'
            },
            {
              key: 'php-fpm.processes_active',
              title: '活跃进程数'
            }
          ]
        case 'rabbitmq':
          return [
            {
              key: 'net.tcp.service.perf[http,"{HOST.CONN}","{$RABBITMQ.API.PORT}"]',
              title: '服务响应时间 (秒)'
            }
          ]
        case 'apache':
          return [
            {
              key: 'apache.requests.rate',
              title: '请求数'
            },
            {
              key: 'apache.workers.closing',
              title: '处于正在关闭状态的 Worker 数'
            },
            {
              key: 'apache.workers.dnslookup',
              title: '处于 DNS 解析状态的 Worker 数'
            },
            {
              key: 'apache.workers.finishing',
              title: '处于停止状态的 Worker 数'
            },
            {
              key: 'apache.workers.cleanup',
              title: '处于清理状态的 Worker 数'
            },
            {
              key: 'apache.workers.keepalive',
              title: '处于保持连接状态的 Worker 数'
            },
            {
              key: 'apache.workers.logging',
              title: '处于记录日志状态的 Worker 数'
            },
            {
              key: 'apache.workers.reading',
              title: '处于读状态的 Worker 数'
            },
            {
              key: 'apache.workers.sending',
              title: '处于发送状态的 Worker 数'
            },
            {
              key: 'apache.workers.starting',
              title: '处于正在启动状态的 Worker 数'
            },
            {
              key: 'apache.workers.waiting',
              title: '处于等待连接状态的 Worker 数量'
            }
          ]
        case 'tomcat':
          return [
            {
              key: 'jmx["java.lang:type=GarbageCollector,name=Copy","CollectionCount"]',
              title: '每秒 GC 副本收集数'
            },
            {
              key: 'jmx["java.lang:type=GarbageCollector,name=Copy","CollectionTime"]',
              title: 'GC 副本收集时间'
            },
            {
              key: 'jmx["java.lang:type=Memory","HeapMemoryUsage.committed"]',
              title: '已提交的堆内存'
            },
            {
              key: 'jmx["java.lang:type=Memory","HeapMemoryUsage.used"]',
              title: '已使用的堆内存'
            },
            {
              key: 'jmx["java.lang:type=Memory","NonHeapMemoryUsage.used"]',
              title: '已使用的非堆内存'
            },
            {
              key: 'jmx["java.lang:type=MemoryPool,name=CMS Perm Gen","Usage.used"]',
              title: '已使用的 CMS 年老代'
            },
            {
              key: 'jmx["java.lang:type=GarbageCollector,name=Copy","CollectionCount"]',
              title: '已使用的 CMS 永久代'
            },
            {
              key: 'jmx["java.lang:type=MemoryPool,name=PS Old Gen","Usage.used"]',
              title: '已使用的 PS 年老代'
            },
            {
              key: 'jmx["java.lang:type=MemoryPool,name=PS Perm Gen","Usage.used"]',
              title: '已使用的 PS 永久代'
            },
            {
              key: 'jmx["java.lang:type=Threading","TotalStartedThreadCount"]',
              title: '总线程数'
            },
            {
              key: 'jmx["java.lang:type=Threading","ThreadCount"]',
              title: '跃线程数'
            }
          ]
        case 'kafka':
          return [
            {
              key: 'jmx["kafka.server:type=Request","queue-size"]',
              title: '请求队列大小'
            },
            {
              key: 'jmx["kafka.server:type=BrokerTopicMetrics,name=MessagesInPerSec","Count"]',
              title: '每秒消息数'
            },
            {
              key: 'jmx["kafka.server:type=BrokerTopicMetrics,name=BytesInPerSec","Count"]',
              title: '每秒字节数'
            }
          ]
        case 'zookeeper':
          return [
            {
              key: 'zookeeper.num_alive_connections',
              title: '活跃连接数'
            },
            {
              key: 'zookeeper.connection_drop_count.rate',
              title: '每秒丢弃连接数'
            },
            {
              key: 'zookeeper.bytes_received_count.rate',
              title: '每秒接收的字节数'
            },
            {
              key: 'zookeeper.commit_count.rate',
              title: '每秒提交数'
            }
          ]
        case 'tongweb':
          return [
            {
              key: 'jmx["java.lang:type=GarbageCollector,name=Copy","CollectionCount"]',
              title: '每秒 GC 副本收集数'
            },
            {
              key: 'jmx["java.lang:type=GarbageCollector,name=Copy","CollectionTime"]',
              title: 'GC 副本收集时间'
            },
            {
              key: 'jmx["java.lang:type=Memory","HeapMemoryUsage.committed"]',
              title: '已提交的堆内存'
            },
            {
              key: 'jmx["java.lang:type=Memory","HeapMemoryUsage.used"]',
              title: '已使用的堆内存'
            },
            {
              key: 'jmx["java.lang:type=Memory","NonHeapMemoryUsage.used"]',
              title: '已使用的非堆内存'
            },
            {
              key: 'jmx["java.lang:type=MemoryPool,name=CMS Perm Gen","Usage.used"]',
              title: '已使用的 CMS 年老代'
            },
            {
              key: 'jmx["java.lang:type=GarbageCollector,name=Copy","CollectionCount"]',
              title: '已使用的 CMS 永久代'
            },
            {
              key: 'jmx["java.lang:type=MemoryPool,name=PS Old Gen","Usage.used"]',
              title: '已使用的 PS 年老代'
            },
            {
              key: 'jmx["java.lang:type=MemoryPool,name=PS Perm Gen","Usage.used"]',
              title: '已使用的 PS 永久代'
            },
            {
              key: 'jmx["java.lang:type=Threading","TotalStartedThreadCount"]',
              title: '总线程数'
            },
            {
              key: 'jmx["java.lang:type=Threading","ThreadCount"]',
              title: '跃线程数'
            }
          ]
        case 'tonghttpserver':
          return [
            {
              key: 'tonghttpserver.requests.total.rate',
              title: '请求数'
            },
            {
              key: 'tonghttpserver.connections.active',
              title: '活跃连接数'
            },
            {
              key: 'tonghttpserver.connections.accepted.rate',
              title: '接受连接数'
            },
            {
              key: 'tonghttpserver.connections.dropped.rate',
              title: '丢弃连接数'
            },
            {
              key: 'tonghttpserver.connections.handled.rate',
              title: '已处理连接数'
            }
          ]
        default:
          return []
      }
    }
  },
  data () {
    return {
      datetimeRange: [moment().subtract(6, 'hour'), moment()],
      historyFunc: getMiddlewareMonitorHistory
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="less">
.software-page {
  padding: 0 4px;
  .module-container {
    position: relative;
    background: #fff;
    border-radius: 6px;
    padding: 16px 16px 8px 16px;
    margin-right: 1px;
    overflow: hidden;
    border: 1px solid #e5e5e5;

    header {
      font-size: 16px;
      font-weight: 500;
      color: #096dd9;
      line-height: 22px;
    }

    .ant-descriptions-item-content {
      font-size: 14px;
    }
  }
}
</style>
